import React, { useState } from 'react';
import css from './CustomHeroSection.module.css';
import classNames from 'classnames';
import ReactPlayer from 'react-player';
import { manageDisableScrolling } from '../../../../ducks/ui.duck';
import DOMPurify from 'dompurify';
import { Button, Modal, ResponsiveImage } from '../../../../components';
import IconCollection from '../../../../components/IconCollection/IconCollection';
import { formatMoney } from '../../../../util/currency';
import { FILM_LABEL, FILM_PRODUCTS, SERIES_LABEL } from '../../../../util/types';
import StarRatings from 'react-star-ratings';
import { formatCardDuration, formatLabel } from '../../../../util/dataExtractor';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../../../util/routes';
import { createSlug } from '../../../../util/urlHelpers';
import { getCurrentDeviceWidth } from '../../../../components/CurrentViewPort/CurrentViewPort';


const calculateAverage = reviews => {
  if (reviews?.length === 0) {
    return 0;
  }
  const sum =
    reviews &&
    reviews?.reduce((accumulator, currentValue) => accumulator + currentValue.attributes.rating, 0);
  const average = sum / reviews?.length;
  return average;
};

const ExpandableDescription = ({ description, limit = 170, intl }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const sanitizedContent = DOMPurify.sanitize(description);

  // Function to toggle between full and limited content
  const toggleContent = () => {
    setIsExpanded(!isExpanded);
  };

  // Strip HTML tags and get plain text
  const getTextContent = (htmlContent) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlContent;
    return tempElement.textContent || tempElement.innerText || '';
  };

  const fullText = getTextContent(sanitizedContent);
  const truncatedText = fullText.slice(0, limit);

  const readMoreMessage = intl.formatMessage({ id: "ListingDetails.readMoreMessage" });
  const readLessMessage = intl.formatMessage({ id: "ListingDetails.readLessMessage" });

  return (
    <div className={css.description}>
      <span>
        {isExpanded ? (
          <span dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
        ) : (
          `${truncatedText}...`
        )}
        <span onClick={toggleContent} className={isExpanded ? css.readLess : ''}>
          {isExpanded ? readLessMessage : readMoreMessage}
        </span>
      </span>
    </div>
  );
};

const CustomHeroListing = ({ listing, intl }) => {
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const [showSubtitles, setShowSubTitles] = useState(false);
  const [showTrailer, setShowTrailer] = useState(false)

  const { title, publicData, price } = listing.attributes;
  const {
    subtitle_selection,
    rating,
    marketingTrailer,
    primary_genre,
    episodeCount,
    listingType,
    marketingBanner,
    marketingPoster,
    listingRating,
    filmVideo,
  } = publicData;

  // const bannerUrl = marketingBanner?.url ?? "";
  const bannerUrl = `${process.env.REACT_APP_GUMLET_SOURCE_URL}/${marketingBanner?.key}`
  const deviceWidth = getCurrentDeviceWidth();
  // Inline style for dynamic background image
  const backgroundStyle = {
    backgroundImage: deviceWidth > 767 ? ` url(${bannerUrl})` : `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 29.41%, #000 75.78%), url(${bannerUrl})`,
    // backgroundSize: 'cover',
    // backgroundPosition: 'center'
  };
  const primaryGenereMaybe = Array.isArray(primary_genre)
    ? <span>{primary_genre.map(g => formatLabel(g)).join(', ')}</span>
    : !!primary_genre
      ? <span>{formatLabel(primary_genre)}</span>
      : '';

  const ratingMaybe = rating ? <span>  {rating}  </span> : '';

  const buyButtonText = intl.formatMessage({ id: listingType == FILM_PRODUCTS ? "ListingDetails.buyButtonText" : "ListingDetails.buySeriesButtonText" })
  const priceMaybe = price && price.amount && price.currency
    ? `${buyButtonText}: ${formatMoney(intl, price)}`
    : null;

  const routeToListing = id => {
    history.push(
      createResourceLocatorString(
        'ListingPage',
        routeConfiguration,
        { id: listing?.id?.uuid, slug: createSlug(title) },
        {}
      )
    );
  };

  const handleWatchTrailer = () => {
    history.push(
      createResourceLocatorString(
        'ListingPage',
        routeConfiguration,
        { id: listing?.id?.uuid, slug: createSlug(title) },
        { trailer: 'show' }
      )
    );
  };

  const subtitlesText = intl.formatMessage({ id: "ListingDetails.subtitles" })
  const trailerMissingText = intl.formatMessage({ id: "ListingDetails.trailerMissing" })
  const watchTrailerButtonText = intl.formatMessage({ id: "ListingDetails.watchTrailerButtonText" });
  const addToWishListButtonText = intl.formatMessage({ id: "ListingDetails.addedToWishListButtonText" });

  return (
    <div className={css.container} >
      <div className={css.overlay} style={backgroundStyle}></div>
      <div className={css.content}>
        <div className={css.leftSection} onClick={routeToListing}>
          <ResponsiveImage
            alt={title}
            className={css.poster}
            variants={['default']}
            gumletImage={{
              sourceUrl: process.env.REACT_APP_GUMLET_SOURCE_URL,
              key: marketingPoster?.key || "",
            }}
          />
        </div>
        <div className={css.rightSection}>
          <h2 className={css.title} onClick={routeToListing}>{title
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')}</h2>
          {listingRating ? <div className={css.reviews}>
            {listingRating ? listingRating : null}
            <StarRatings
              svgIconViewBox="0 0 40 37"
              svgIconPath="M20 0L26.113 11.5862L39.0211 13.8197L29.891 23.2138L31.7557 36.1803L20 30.4L8.2443 36.1803L10.109 23.2138L0.97887 13.8197L13.887 11.5862L20 0Z"
              starRatedColor="#ffb802"
              // starEmptyColor="#ffffff"
              rating={listingRating ? listingRating : 0}
              starDimension="25px"
              starSpacing="2px"
            />
          </div> : null}
          <div className={css.movieDetails}>
            <div className={css.primaryGenere}>
              {listingType == FILM_PRODUCTS ? FILM_LABEL : SERIES_LABEL}&nbsp;|&nbsp;
              {primaryGenereMaybe}&nbsp;|&nbsp;
              {formatLabel(subtitle_selection && subtitle_selection[0])}&nbsp;|&nbsp;
              {ratingMaybe}&nbsp;|&nbsp;
              {episodeCount ? <span>{episodeCount} Episodes |&nbsp;</span> : <span>{formatCardDuration(filmVideo?.duration)}&nbsp;|&nbsp;</span>}
              <span><IconCollection icon="cc-icon" /></span>&nbsp;|&nbsp;
              <div className={css.subtitleContainer}>
                <p
                  className={css.subtitleHeading}
                  onMouseEnter={() => !showSubtitles ? setShowSubTitles(true) : null}
                  onMouseLeave={() => !!showSubtitles ? setShowSubTitles(false) : null}
                >
                  &nbsp;{subtitlesText}
                  &nbsp;<IconCollection icon="icon-arrow-down" />
                </p>
                <ul className={classNames(css.subtitles, showSubtitles ? css.show : '')}>
                  {(subtitle_selection || []).map(st => (
                    <li key={st.key}>{formatLabel(st)}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className={css.trailerWrapper}>
            <Modal className={css.trailerModal} onManageDisableScrolling={manageDisableScrolling} isOpen={showTrailer} onClose={() => setShowTrailer(false)}>
              {marketingTrailer && marketingTrailer.playback_url
                ? (<ReactPlayer
                  url={marketingTrailer.playback_url}
                  controls
                  width="100%"
                  height="100%"
                />)
                : <div className={css.missingTrailer}>{trailerMissingText}</div>}
            </Modal>
          </div>
          <div className={css.actions}>

            <Button
              className={css.watchTrailerButton}
              onClick={handleWatchTrailer}
            ><span><IconCollection icon="icon-watch-trailer" />{watchTrailerButtonText}</span></Button>

          </div>
        </div>
      </div>
    </div>
  );
};


export default CustomHeroListing;
