import React from 'react';

export const tiktokIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.541 6.489C22.3638 6.39735 22.1913 6.29689 22.0241 6.188C21.5384 5.86684 21.093 5.48846 20.6976 5.061C19.7071 3.92817 19.3373 2.779 19.202 1.97517H19.2066C19.0935 1.30667 19.1401 0.875 19.1483 0.875H14.6391V18.3085C14.6391 18.5418 14.6391 18.774 14.6298 19.0027C14.6298 19.0307 14.6263 19.0563 14.6251 19.0878C14.6251 19.0995 14.6251 19.1135 14.6216 19.1263V19.1368C14.5743 19.7622 14.374 20.3663 14.0383 20.8961C13.7026 21.4258 13.2419 21.865 12.6966 22.1748C12.1278 22.4984 11.4844 22.6681 10.83 22.6672C8.72997 22.6672 7.02663 20.9545 7.02663 18.8393C7.02663 16.723 8.72997 15.0103 10.83 15.0103C11.2278 15.0103 11.6233 15.0733 12.0013 15.197L12.0071 10.605C10.8591 10.4569 9.69293 10.5484 8.58208 10.8737C7.47123 11.199 6.43988 11.751 5.55313 12.495C4.77596 13.17 4.12255 13.9754 3.6223 14.875C3.43213 15.2028 2.71347 16.5223 2.62713 18.662C2.5723 19.8753 2.93747 21.1353 3.1113 21.6545V21.6662C3.2198 21.9718 3.64447 23.0172 4.33513 23.898C4.8921 24.605 5.55016 25.2261 6.28813 25.7413V25.7297L6.29863 25.7413C8.4803 27.223 10.9011 27.1262 10.9011 27.1262C11.32 27.1087 12.7235 27.1262 14.3171 26.3713C16.0846 25.5337 17.0903 24.2865 17.0903 24.2865C17.7333 23.5411 18.2446 22.6917 18.6023 21.7747C19.0106 20.7013 19.146 19.4157 19.146 18.9023V9.65183C19.2008 9.6845 19.93 10.1663 19.93 10.1663C19.93 10.1663 20.98 10.8395 22.6168 11.277C23.7905 11.5885 25.3736 11.655 25.3736 11.655V7.1785C24.8195 7.23917 23.6936 7.06417 22.5398 6.49017" fill="white"/>
    </svg>
    
  );
};
