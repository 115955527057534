import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import css from './CustomFilterSection.module.css';
import IconCollection from '../../../../components/IconCollection/IconCollection';
import RabbelLogo from '../../../../assets/rabel-logo-white.png';
import { createResourceLocatorString } from '../../../../util/routes';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { CREATOR_SEARCH_ID, LISTING_SEARCH_ID, LISTING_TYPE_FILMS, LISTING_TYPE_SERIES } from '../../../../constants';
import { ResponsiveImage } from '../../../../components';
import classNames from 'classnames';


const CustomFilterSection = () => {
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();

  const handleClick = (id, category) => {
    history.push(
      createResourceLocatorString(category ? 'SearchPageGenre' : 'SearchPage', routeConfiguration, {
        searchId: id,
        genre: category,
      })
    );
  };

  return (
    <div className={css.customFilterContainer}>
      <div className={css.customFilterSection}>
        <div className={css.customFilterItem} onClick={() => handleClick(CREATOR_SEARCH_ID)}>
          <div className={css.customFilterItemTitle}>
            <ResponsiveImage url={RabbelLogo} alt={'Rabel logo'} /> Creators
          </div>
        </div>
        <div
          className={css.customFilterItem}
          onClick={() => handleClick(LISTING_SEARCH_ID, LISTING_TYPE_FILMS)}
        >
          <div className={css.customFilterItemTitle}>
            <IconCollection icon="icon-film-white" /> Films
          </div>
        </div>
        <div className={classNames(css.customFilterItem, css.disabled)} onClick={() => {}}>
          <div className={css.customFilterItemTitle}>
            <IconCollection icon="icon-series-white" />
            <div>
              <span>Series</span>
              <span className={css.comingSoon}>(Coming Soon)</span>
            </div>
          </div>
        </div>
        <div className={classNames(css.customFilterItem, css.disabled)} onClick={() => {}}>
          <div className={css.customFilterItemTitle}>
            <IconCollection icon="icon-live-white" />
            <div>
              <span> Live Events</span>
              <span className={css.comingSoon}>(Coming Soon)</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomFilterSection;
