import React from 'react';
import CustomFilterSection from '../CustomFilterSection/CustomFilterSection';
import CustomHeroSection from '../CustomHeroSection/CustomHeroSection';
import NewReleaseSection from '../NewReleaseSection/NewReleaseSection';
import SeriesSection from '../SeriesSection/SeriesSection';
import FilmsSection from '../FilmsSection/FilmsSection';
import { CUSTOM_FILTERS, FILMS, HERO, NEW_RELEASES, SERIES } from '../../../../constants';

const OtherSection = ({ sectionName, description }) => {
  const renderComponent = sectionName => {
    switch (sectionName) {
      case HERO:
        return <CustomHeroSection />;

      case CUSTOM_FILTERS:
        return <CustomFilterSection />;

      case NEW_RELEASES:
        return <NewReleaseSection sectionName={sectionName} description={description} />;

      case SERIES:
        return <SeriesSection sectionName={sectionName} description={description} />;

      case FILMS:
        return <FilmsSection sectionName={sectionName} description={description} />;

      default:
        console.warn(`Section ${sectionName} not found in Other sections!`);
        return null;
    }
  };

  return renderComponent(sectionName);
};

export default OtherSection;
