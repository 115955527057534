import React from 'react';

export const xIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.1887 3.75H26.0225L17.6475 13.2812L27.5 26.25H19.785L13.7437 18.3837L6.83 26.25H2.9925L11.9513 16.0563L2.5 3.75H10.41L15.8725 10.94L22.1887 3.75ZM20.8438 23.965H22.9688L9.255 5.915H6.975L20.8438 23.965Z" fill="white"/>
    </svg>
    
  );
};
