import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFilmsListings, filmsListingsSelector } from '../../../LandingPage/LandingPage.duck';
import { CarouselSection } from '../../../../components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../../../util/routes';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { LISTING_SEARCH_ID, LISTING_TYPE_FILMS } from '../../../../constants';

const FilmsSection = ({ sectionName, description }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const filmListings = useSelector(filmsListingsSelector);

  useEffect(() => {
    dispatch(fetchFilmsListings());
  }, []);

  const showMoreFilms = () => {
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration,
        { searchId: LISTING_SEARCH_ID },
        { category: LISTING_TYPE_FILMS }
      )
    );
  };

  return filmListings?.length ? (
    <CarouselSection
      name={sectionName}
      description={description}
      items={filmListings}
      handleAction={showMoreFilms}
    />
  ) : null;
};

export default FilmsSection;
