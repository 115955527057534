import React from 'react';

export const youtubeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
    <path d="M11.1375 0H9.61875L8.60625 3.88125L7.59375 0H5.90625C6.24375 1.0125 6.58125 1.85625 6.91875 2.86875C7.425 4.21875 7.7625 5.4 7.7625 6.075V10.125H9.28125V6.075L11.1375 0ZM15.1875 7.59375V5.0625C15.1875 4.21875 15.0187 3.7125 14.6812 3.20625C14.3438 2.7 13.8375 2.53125 13.1625 2.53125C12.4875 2.53125 11.9813 2.86875 11.6438 3.375C11.3063 3.7125 11.1375 4.21875 11.1375 5.0625V7.7625C11.1375 8.60625 11.3063 9.1125 11.6438 9.45C11.9813 9.95625 12.4875 10.125 13.1625 10.125C13.8375 10.125 14.3438 9.7875 14.6812 9.28125C15.0187 9.1125 15.1875 8.4375 15.1875 7.59375ZM13.8375 7.93125C13.8375 8.60625 13.6688 8.94375 13.1625 8.94375C12.6563 8.94375 12.4875 8.60625 12.4875 7.93125V4.725C12.4875 4.05 12.6563 3.7125 13.1625 3.7125C13.6688 3.7125 13.8375 4.05 13.8375 4.725V7.93125ZM20.25 10.125V2.53125H18.9V8.26875C18.5625 8.775 18.3937 8.94375 18.0562 8.94375C17.8875 8.94375 17.7188 8.775 17.7188 8.60625V2.53125H16.3687V8.4375C16.3687 8.94375 16.3688 9.28125 16.5375 9.61875C16.5375 9.95625 16.875 10.125 17.3813 10.125C17.8875 10.125 18.3937 9.7875 18.9 9.28125V10.125H20.25ZM20.925 17.7188C20.4187 17.7188 20.25 18.0563 20.25 18.7313V19.4062H21.6V18.7313C21.6 18.0563 21.4312 17.7188 20.925 17.7188ZM16.0312 17.7188C15.8625 17.7188 15.525 17.8875 15.3563 18.0562V22.6125C15.525 22.7812 15.8625 22.95 16.0312 22.95C16.3687 22.95 16.5375 22.6125 16.5375 21.9375V18.7313C16.5375 18.0563 16.3687 17.7188 16.0312 17.7188Z" fill="white"/>
    <path d="M24.2998 14.0063C23.9623 12.8251 22.9498 11.8126 21.9373 11.8126C19.2373 11.4751 16.3686 11.4751 13.4998 11.4751C10.6311 11.4751 7.93105 11.4751 5.0623 11.8126C4.0498 11.8126 3.0373 12.8251 2.6998 14.0063C2.3623 15.6938 2.3623 17.5501 2.3623 19.2376C2.3623 20.9251 2.3623 22.7813 2.6998 24.4688C3.0373 25.6501 3.88105 26.4938 5.0623 26.6626C7.93105 27.0001 10.6311 27.0001 13.4998 27.0001C16.3686 27.0001 19.0686 27.0001 21.9373 26.6626C23.1186 26.4938 24.1311 25.6501 24.2998 24.4688C24.6373 22.7813 24.6373 20.9251 24.6373 19.2376C24.6373 17.5501 24.6373 15.6938 24.2998 14.0063ZM8.7748 15.5251H7.0873V24.1313H5.56855V15.5251H4.0498V14.0063H8.7748V15.5251ZM12.8248 24.1313H11.4748V23.2876C10.9686 23.9626 10.4623 24.1313 9.95605 24.1313C9.4498 24.1313 9.28105 23.9626 9.1123 23.6251C9.1123 23.4563 8.94355 23.1188 8.94355 22.4438V16.5376H10.2936V22.4438C10.2936 22.6126 10.4623 22.7813 10.6311 22.7813C10.9686 22.7813 11.1373 22.6126 11.4748 22.1063V16.5376H12.8248V24.1313ZM17.8873 21.7688C17.8873 22.4438 17.8873 22.9501 17.7186 23.2876C17.5498 23.7938 17.2123 24.1313 16.7061 24.1313C16.1998 24.1313 15.6936 23.7938 15.3561 23.2876V23.9626H14.0061V14.0063H15.3561V17.2126C15.8623 16.7063 16.1998 16.3688 16.7061 16.3688C17.2123 16.3688 17.5498 16.7063 17.7186 17.2126C17.8873 17.5501 17.8873 18.0563 17.8873 18.7313V21.7688ZM22.9498 20.5876H20.2498V21.9376C20.2498 22.6126 20.4186 22.9501 20.9248 22.9501C21.2623 22.9501 21.4311 22.7813 21.5998 22.4438V21.6001H22.9498V22.6126C22.9498 22.9501 22.7811 23.1188 22.6123 23.4563C22.2748 23.9626 21.7686 24.3001 20.9248 24.3001C20.2498 24.3001 19.7436 23.9626 19.2373 23.4563C18.8998 23.1188 18.7311 22.4438 18.7311 21.7688V19.2376C18.7311 18.3938 18.8998 17.8876 19.0686 17.5501C19.4061 17.0438 19.9123 16.7063 20.7561 16.7063C21.4311 16.7063 21.9373 17.0438 22.2748 17.5501C22.6123 17.8876 22.6123 18.5626 22.6123 19.2376V20.5876H22.9498Z" fill="white"/>
  </svg>
  );
};
