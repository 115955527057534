import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLatestContent, recentListingsSelector } from '../../../LandingPage/LandingPage.duck';
import css from './CustomHeroSection.module.css';
import CustomHeroListing from './CustomHeroListing';
import { useIntl } from '../../../../util/reactIntl';
import Slider from "react-slick";
import Skeleton from '../../../../components/Skeleton/Skeleton';



const CustomHeroSection = () => {

  const intl = useIntl();
  const dispatch = useDispatch();
  const recentListing = useSelector(recentListingsSelector);
  const recent5Listings = (recentListing || []).slice(0, 5);

  useEffect(() => {
    dispatch(fetchLatestContent());
  }, []);

  const settings = {
    className: "slider variable-width",
    dots: true,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 2000,

    arrows: false,
    fade: true, // Add fade effect
    cssEase: 'linear', // Smooth transition
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          autoplay: false,
          vertical: false,
        },
      },
    ],
  };

  if (recent5Listings.length === 0) {
    return <div className={css.sliderContainer}>
      <div
        className={css.container}
      >
        <div className={css.content}>
      <div className={css.leftSection}>
        <Skeleton width="100%" height={'400px'} rounded={9} />
        </div>
        <div className={css.rightSection}>
          <Skeleton width="70%" height={'40px'} rounded={0} bottom={12}/><br/>
          <Skeleton width="30%" height={'10px'} rounded={0}  bottom={12}/><br/>
          <Skeleton width="60%" height={'10px'} rounded={0} bottom={12} /><br/>
          <Skeleton width="20%" height={'40px'} rounded={30} bottom={2} />
        </div>
      </div>
      </div>


    </div>;
  }


  return <div>
    <div className={css.sliderContainer}>
      <Slider {...settings} className={css.slider}>
        {recent5Listings.map((listing, index) => (
          <React.Fragment key={index}>
            <CustomHeroListing listing={listing} intl={intl} />
          </React.Fragment>
        ))}
      </Slider>
    </div>
  </div>;
};

export default CustomHeroSection;
