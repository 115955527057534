import algoliasearch from 'algoliasearch';
import React from 'react';
import { Configure, InstantSearch } from 'react-instantsearch';
import { useQuery } from '../../util/data';
import GuestAlgoliaLandingPage from './GuestAlgoliaLandingPage/GuestAlgoliaLandingPage';
import AuthAlogliaLandingPage from './AuthAlogliaLandingPage/AuthAlogliaLandingPage';
import { useLocation } from 'react-router-dom';
import { FOR_YOU, LISTING_STATE_PUBLISHED, TRENDING_CREATORS, USER_STATE_BANNED } from '../../constants';
import { useStore } from 'react-redux';
import { listingPreferencesSelector } from '../../ducks/user.duck';

const appId = process.env.REACT_APP_ALGOLIA_APP_ID;
const apiKey = process.env.REACT_APP_ALGOLIA_ADMIN_API_KEY;
const listingIndex = process.env.REACT_APP_ALGOLIA_LISTINGS_INDEX;
const creatorIndex = process.env.REACT_APP_ALGOLIA_USERS_INDEX;

const searchClient = algoliasearch(appId, apiKey);

const prepareAlgoliaFilters = listingPreferences => {
  if (!Object.keys(listingPreferences).length) {
    return ""
  }

  let filters = '';
  const genreObject = Object.values(listingPreferences);

  for (const genreValue of genreObject) {
    const genreKeys = Object.keys(genreValue);
    filters +=
      genreKeys.map(genreKey => `publicData.primary_genre:${genreKey}`).join(' OR ') + ' OR ';
  }

  // Remove the trailing ' OR ' if filters is not empty
  filters = filters.slice(0, -4);

  return filters;
};

const AlgoliaLandingPage = props => {
  const location = useLocation();
  const store = useStore();

  const { sectionName, isAuthenticated, description } = props;

  const isTrendingCreatorSection = sectionName == TRENDING_CREATORS;
  const indexName = isAuthenticated
    ? isTrendingCreatorSection
      ? `${creatorIndex}_views_desc`
      : `${listingIndex}_views_desc`
    : listingIndex;
  const initialCategory = useQuery(location.search).get('category');
  const defaultState = isTrendingCreatorSection
    ? `NOT state:${USER_STATE_BANNED} AND deleted:false AND NOT privateMode:true`
    : `NOT privateMode:true AND state:${LISTING_STATE_PUBLISHED} AND markAsDraft:false`;

  const initialUiState = initialCategory
    ? {
        [indexName]: {
          menu: { 'publicData.listingType': initialCategory },
        },
      }
    : {};

  const listing_preferences = listingPreferencesSelector(store.getState()) || {};
  const forYouListingFilters = prepareAlgoliaFilters(listing_preferences);
  const finalState =
    sectionName === FOR_YOU && forYouListingFilters.length
      ? `(${defaultState}) AND (${forYouListingFilters})`
      : defaultState;

  return (
    <InstantSearch
      key={indexName} // Forces reinitialization on indexName change
      indexName={indexName}
      searchClient={searchClient}
      initialUiState={{ ...initialUiState }}
    >
      <Configure hitsPerPage={10} filters={finalState} />
      {isAuthenticated ? (
        <AuthAlogliaLandingPage
          sectionName={sectionName}
          description={description}
        />
      ) : (
        <GuestAlgoliaLandingPage indexName={indexName} />
      )}
    </InstantSearch>
  );
};

export default AlgoliaLandingPage;
