import React from 'react';

export const facebookIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
    <path d="M17.75 3.59375C16.2167 3.59375 14.7462 4.20284 13.662 5.28704C12.5778 6.37123 11.9687 7.84172 11.9687 9.375V12.5938H8.875C8.72 12.5938 8.59375 12.7188 8.59375 12.875V17.125C8.59375 17.28 8.71875 17.4062 8.875 17.4062H11.9687V26.125C11.9687 26.28 12.0938 26.4062 12.25 26.4062H16.5C16.655 26.4062 16.7812 26.2812 16.7812 26.125V17.4062H19.9025C20.0312 17.4062 20.1437 17.3188 20.175 17.1938L21.2375 12.9437C21.248 12.9023 21.2488 12.859 21.24 12.8171C21.2312 12.7753 21.213 12.736 21.1867 12.7022C21.1604 12.6685 21.1268 12.6412 21.0884 12.6224C21.05 12.6036 21.0078 12.5938 20.965 12.5938H16.7812V9.375C16.7812 9.24778 16.8063 9.12181 16.855 9.00428C16.9037 8.88674 16.975 8.77995 17.065 8.68999C17.1549 8.60003 17.2617 8.52868 17.3793 8.47999C17.4968 8.43131 17.6228 8.40625 17.75 8.40625H21C21.155 8.40625 21.2812 8.28125 21.2812 8.125V3.875C21.2812 3.72 21.1562 3.59375 21 3.59375H17.75Z" fill="white"/>
  </svg>
    
  );
};
